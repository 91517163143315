@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Custome Scroll Bar */
.custome-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.custome-scrollbar::-webkit-scrollbar-track {
  background: #e0e0e0;
}

.custome-scrollbar::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 10px;
}

.custome-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #333;
}

/* Custome Style For React Toggle */
.react-toggle--checked .react-toggle-thumb {
  left: 28px !important;
  border-color: #247eb3 !important;
}

.react-toggle--checked .react-toggle-track {
  background-color: #0c4a6e !important;
}

.react-toggle-thumb {
  width: 15px !important;
  height: 15px !important;
  top: 3.5px !important;
  left: 4px !important;
}

.react-toggle-track {
  width: 46px !important;
  height: 22px !important;
}

input:focus + span,
input:not(:placeholder-shown) + span {
  @apply scale-75 -translate-y-4 bg-white translate-x-1; /* Adjust the translate values as needed */
}
